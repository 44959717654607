import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Channel, Socket } from 'phoenix'
import { useProfileContext } from '@/contexts/ProfileContext'
import { useClientAccessToken } from '@/services/OAuthService'
import { useTranslate } from '@/utils/translate/translate-client'
import { getUserDisplayName } from '@/utils/users'
import { useGuildMember } from '../GuildUserService/GuildUserService'
import { useUser } from '../UserService'
import {
  ChatbotMessage,
  ChatbotMessageType,
  ChatFeedMessage,
  ChatSocketValues,
  ChatStatus,
  SocketChannelTopic,
} from './types'
import { isChatMessage } from './utils'

const WatchPartyChatContext = React.createContext<ChatSocketValues>({
  chatStatus: 'offline',
  connected: false,
  connectingMessage: '',
  loading: true,
  messages: [] as ChatFeedMessage[],
  notConnectedMessage: '',
  sendMessage: () => undefined,
  error: undefined,
  userId: undefined,
  userImage: undefined,
  timedOut: false,
  totalViewers: 0,
  projectSlug: '',
})

interface WatchPartyChatContextProviderProps {
  acceptedMessageTypes?: ChatbotMessageType[]
  chatCode?: string | null
  children: React.ReactNode
  connectingMessage?: string
  initialMessages?: ChatFeedMessage[]
  notConnectedMessage?: string
  context?: 'illuminate-2023'
  projectSlug: string
  /** If provided, filters out any messages where messageFilter(message) returns false */
  messageFilter?: (message: ChatFeedMessage) => boolean | undefined
}

export const WatchPartyChatContextProvider: React.FC<WatchPartyChatContextProviderProps> = ({
  acceptedMessageTypes,
  chatCode,
  children,
  connectingMessage: connectingMessageOverride,
  initialMessages,
  notConnectedMessage: notConnectedMessageOverride,
  context,
  projectSlug,
  messageFilter,
}) => {
  const { t } = useTranslate('common')
  const defaultInitialMessages = [
    {
      message: t('welcome', 'Welcome!'),
      publicId: 'Angel Studios',
      ts: new Date(),
      type: ChatbotMessageType.app,
    } as ChatbotMessage,
  ]
  const [messages, setMessages] = useState<ChatFeedMessage[]>(initialMessages ?? defaultInitialMessages)
  const [activeChannel, setActiveChannel] = useState<Channel | null>(null)
  const [ghostChannel, setGhostChannel] = useState<Channel | null>(null)
  const [socket, setSocket] = useState<Socket | null>(null)
  const chatCodeRef = useRef<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [connecting, setConnecting] = useState<boolean>(false)
  const [connected, setConnected] = useState<boolean>(false)
  const [error, setError] = useState()
  const [timedOut, setTimedOut] = useState<boolean>(false)
  const isShoutInitRef = useRef<boolean>(false)
  const didSendAuthRef = useRef<boolean>(false)
  const [totalViewers, setTotalViewers] = useState<number>(0)
  const { user } = useUser()
  const { token } = useClientAccessToken()
  const { badges, profile } = useProfileContext()
  const [chatStatus, setChatStatus] = useState<ChatStatus>('offline')
  const guildUser = useGuildMember()

  const connectingMessage = connectingMessageOverride || t('connectingToChat', 'Connecting to chat...')
  const notConnectedMessage =
    notConnectedMessageOverride ||
    t(
      'chatNotConnected',
      "There's been a problem connecting to chat. Please ensure your browser has cookies enabled and try again.",
    )
  const userId = user?.uuid

  const updateViewCount = useCallback(
    (viewers: number) => {
      if (viewers !== totalViewers) {
        setTotalViewers(viewers)
      }
    },
    [totalViewers],
  )

  useEffect(() => {
    if (activeChannel && !didSendAuthRef?.current && token && chatStatus === 'online') {
      activeChannel.push('auth', { token })
      didSendAuthRef.current = true
    }
  }, [activeChannel, token, chatStatus])

  const connect = useCallback(() => {
    setConnecting(true)

    const url = `${process.env.NEXT_PUBLIC_CHAT_SERVER}/socket`

    const socket = new Socket(url)
    socket.connect()

    setSocket(socket)

    const ghostChannel = socket.channel(`ghost:${chatCode}`)
    ghostChannel.join().receive('ok', () => {
      setGhostChannel(ghostChannel)
    })

    const channel = socket.channel(`chat:${chatCode}`)

    didSendAuthRef.current = false
    setTimedOut(false)

    channel
      .join()
      .receive('ok', (payload) => {
        setActiveChannel(channel)
        setConnected(true)
        updateViewCount(payload.userCount || 0)
        setLoading(false)
        setConnecting(false)
        setChatStatus(payload.chat_status)
      })
      .receive('error', (error) => {
        setLoading(false)
        setError(error)
        setConnecting(false)
      })
      .receive('timeout', () => {
        setLoading(false)
        setTimedOut(true)
        setConnecting(false)
      })
  }, [chatCode, updateViewCount])

  useEffect(() => {
    if (activeChannel) {
      return () => {
        activeChannel?.leave()
      }
    }
  }, [activeChannel])

  useEffect(() => {
    if (ghostChannel) {
      return () => {
        ghostChannel?.leave()
      }
    }
  }, [ghostChannel])

  useEffect(() => {
    if (socket) {
      return () => {
        socket?.disconnect()
      }
    }
  }, [socket])

  useEffect(() => {
    if (!chatCode || (chatCode === chatCodeRef.current && activeChannel) || connecting) {
      return
    }

    chatCodeRef.current = chatCode

    return connect()
  }, [chatCode, activeChannel, connect, connecting])

  const sliceList = useCallback(
    (message: ChatFeedMessage) => {
      if (messageFilter && !messageFilter(message)) return

      setMessages((currentMessages) => {
        const slice = currentMessages.slice(Math.max(currentMessages.length - 100, 0))
        return [...slice, message]
      })
    },
    [messageFilter],
  )

  const updateList = useCallback(
    (message: ChatFeedMessage) => {
      if (!acceptedMessageTypes) {
        sliceList(message)
      } else if (acceptedMessageTypes.indexOf(message.type) > -1) {
        sliceList(message)
      }
    },
    [acceptedMessageTypes, sliceList],
  )

  useEffect(() => {
    if (activeChannel?.state === 'joined' && !isShoutInitRef.current) {
      activeChannel?.on(SocketChannelTopic.shout, updateList)

      activeChannel?.on(SocketChannelTopic.user_count_update, ({ count }) => {
        updateViewCount(count)
      })

      activeChannel?.on(SocketChannelTopic.delete_message, ({ message }) => {
        setMessages((currentMessages) => {
          return [...currentMessages.filter(isChatMessage).filter((m) => m.publicId !== message.publicId)]
        })
      })

      activeChannel?.on('channel_status', ({ chat_status }) => {
        setChatStatus(chat_status)
      })

      activeChannel?.on(SocketChannelTopic.chatbot_message, (message: ChatbotMessage) => {
        if (
          message.type === ChatbotMessageType.pif ||
          message.type === ChatbotMessageType.claim ||
          message.type === ChatbotMessageType.ticket ||
          message.type === ChatbotMessageType.guild
        ) {
          updateList(message)
        }
      })

      isShoutInitRef.current = true
    }
  }, [activeChannel?.state, activeChannel, updateList, updateViewCount])

  const sendMessage = useCallback(
    (text: string) => {
      if (activeChannel && text !== '' && profile && userId) {
        const name = getUserDisplayName({ profile }, t('anonymous', 'Anonymous'))

        const myMessage = {
          name,
          message: text,
          userId: userId,
          avatarUrl: profile?.image || user?.profile?.image || '',
          ...badges,
          metadata: {
            isGuildMember: guildUser?.isGuildMember,
            ...badges,
          },
        }

        activeChannel.push(SocketChannelTopic.shout, myMessage)
      }
    },
    [activeChannel, badges, profile, t, user?.profile?.image, userId, guildUser],
  )

  const userImage = profile?.image ?? user?.profile?.image ?? undefined

  const value = useMemo(() => {
    return {
      context,
      chatStatus,
      connected,
      connectingMessage: connectingMessage as string,
      error,
      loading,
      messages,
      notConnectedMessage: notConnectedMessage as string,
      sendMessage,
      timedOut,
      totalViewers,
      userId: userId as string | undefined,
      userImage,
      projectSlug,
    }
  }, [
    context,
    chatStatus,
    connected,
    connectingMessage,
    error,
    loading,
    messages,
    notConnectedMessage,
    sendMessage,
    timedOut,
    totalViewers,
    userId,
    userImage,
    projectSlug,
  ])

  return <WatchPartyChatContext.Provider value={value}>{children}</WatchPartyChatContext.Provider>
}

export const useWatchPartyChat = (): ChatSocketValues => {
  return React.useContext(WatchPartyChatContext)
}
