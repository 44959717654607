import { ApolloClient, gql, NormalizedCacheObject } from '@apollo/client'
import {
  GetAvailableUserDiscountsQuery,
  GetAvailableUserDiscountsQueryVariables,
  GetTheatricalLandingReleasesQuery,
  GetTheatricalLandingReleasesQueryVariables,
  GetTheatricalReleaseImagesQuery,
  GetTheatricalReleaseImagesQueryVariables,
  GetTheatricalReleaseQuery,
  GetTheatricalReleaseQueryVariables,
  TicketSalesPromosQuery,
  TicketSalesPromosQueryVariables,
} from '@/types/codegen-federation'
import { logger } from '@/utils/logging'

export const GET_THEATRICAL_RELEASES = gql`
  query getTheatricalReleases($input: TheatricalReleasesInput!) {
    theatricalReleases(input: $input) {
      theatricalRelease {
        id
        theatricalSlug
        isFeaturedRelease
        title
      }
    }
  }
`

export const GET_THEATRICAL_RELEASE = gql`
  query getTheatricalRelease($input: TheatricalReleaseInput!, $region: String!) {
    theatricalRelease(input: $input) {
      theatricalRelease {
        id
        movieRating
        groupProductVariantId
        theatricalSlug
        title
        longDescription
        shortDescription
        mediumDescription
        hypebotCode
        verticalPoster
        horizontalPoster
        projectSlug
        soldOutShowtimeCount
        backdropImage
        backdropMobileImage
        logoImage
        logoImageWeb
        critics {
          company
          scores {
            name
            value
          }
        }
        theatricalReleaseRegions {
          id
          countryCode
          regionName
          ticketsStatus
        }
        movieDuration
        externalCta {
          id
          title
          subtitle
          ctaText
          ctaUrl
          imageUrl
        }
        region(region: $region) {
          id
          trailerUrl
          seoImage
          releaseDate
          redirectTicketsPage
          ticketsEmergencyRedirectUrl
          freeTicketRequestAmount
          isGroupTicketsEnabled
          ticketsStatus
          trailerThumbnail
          regionName
          countryCode
          useSimpleWaitlistOverlay
          defaultTicketingProviderSlug
          partnerUrl
          faqs {
            question
            answer
          }
        }
      }
    }
  }
`

export async function getTheatricalRelease({
  theatricalSlug,
  client,
  region,
}: {
  theatricalSlug: string | null
  client: ApolloClient<NormalizedCacheObject>
  region?: string
}) {
  try {
    if (!theatricalSlug) {
      logger().warn('Theatrical slug missing for theatrical release data')
      throw new Error('theatrical slug missing for theatrical release data')
    }
    const { data } = await client.query<GetTheatricalReleaseQuery, GetTheatricalReleaseQueryVariables>({
      query: GET_THEATRICAL_RELEASE,
      variables: {
        input: {
          theatricalSlug,
        },
        region: region?.toUpperCase() ?? 'US',
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    })
    return data?.theatricalRelease?.theatricalRelease
  } catch (error) {
    logger().error('Fetching Theatrical Release failed', {
      error,
      theatricalSlug,
      context: 'getTheatricalRelease',
    })
    return null
  }
}

export const GET_TICKET_SALES_PROMOS = gql`
  query ticketSalesPromos($input: TicketSalesPromosInput!) {
    ticketSalesPromos(input: $input) {
      id
      activePromosList
      sweepstakesSchedule {
        id
        name
        startDate
        endDate
        items {
          ticketType
          entriesPerTicket
          multiplier
          startDate
          endDate
        }
      }
    }
  }
`

export async function getTicketSalesProps({
  theatricalSlug,
  client,
  date,
}: {
  theatricalSlug: string | null
  client: ApolloClient<NormalizedCacheObject>
  date?: string
}) {
  if (!theatricalSlug) {
    throw new Error('theatrical slug missing for theatrial release data')
  }
  const { data } = await client.query<TicketSalesPromosQuery, TicketSalesPromosQueryVariables>({
    query: GET_TICKET_SALES_PROMOS,
    variables: {
      input: {
        projectSlug: theatricalSlug,
        date,
      },
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
  return data?.ticketSalesPromos?.sweepstakesSchedule
}

export const GET_AVAILABLE_USER_DISCOUNTS = gql`
  query getAvailableUserDiscounts($input: AvailableUserDiscountsInput!) {
    availableUserDiscounts(input: $input) {
      discounts {
        id
        name
        code
        category
        remainingUses
      }
    }
  }
`

export async function getAvailableUserDiscounts({
  email,
  theatricalSlug,
  client,
}: {
  email: string
  theatricalSlug: string | null
  client: ApolloClient<NormalizedCacheObject>
}) {
  if (!theatricalSlug) {
    throw new Error('theatrical slug missing for theatrial release data')
  }
  const { data } = await client.query<GetAvailableUserDiscountsQuery, GetAvailableUserDiscountsQueryVariables>({
    query: GET_AVAILABLE_USER_DISCOUNTS,
    variables: {
      input: {
        email,
        theatricalSlug: theatricalSlug,
      },
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  })
  return data?.availableUserDiscounts?.discounts
}

export const GET_THEATRICAL_RELEASE_IMAGES = gql`
  query getTheatricalReleaseImages($theatricalSlug: String!) {
    project(slug: $theatricalSlug) {
      id
      slug
      title {
        ... on ContentDisplayable {
          id
          logoOriginal: image(category: LOGO, aspect: "original") {
            aspect
            category
            cloudinaryPath
          }
          keyArt16x9: image(category: ANGEL_KEY_ART_1, aspect: "16:9") {
            aspect
            category
            cloudinaryPath
          }
          backdrop16x9: image(category: BACKDROP, aspect: "16:9") {
            aspect
            category
            cloudinaryPath
          }
          backdrop9x16: image(category: BACKDROP, aspect: "9:16") {
            aspect
            category
            cloudinaryPath
          }
        }
      }
    }
  }
`

export async function getTheatricalReleaseImages({
  theatricalSlug,
  client,
}: {
  theatricalSlug: string
  client: ApolloClient<NormalizedCacheObject>
}) {
  const { data } = await client.query<GetTheatricalReleaseImagesQuery, GetTheatricalReleaseImagesQueryVariables>({
    query: GET_THEATRICAL_RELEASE_IMAGES,
    variables: { theatricalSlug },
    errorPolicy: 'all',
  })

  return data?.project?.title
}

export const GET_THEATRICAL_LANDING_RELEASES = gql`
  query getTheatricalLandingReleases($input: TheatricalReleasesInput!) {
    theatricalReleases(input: $input) {
      theatricalRelease {
        backdropImage
        catalogContentId
        horizontalPoster
        id
        isFeaturedRelease
        logoImageWeb
        movieRating
        region {
          id
          countryCode
          releaseDate
          ticketsStatus
        }
        shortDescription
        theatricalSlug
        title
        verticalPoster
      }
    }
  }
`

export async function getTheatricalLandingReleases({
  client,
  locale,
}: {
  client: ApolloClient<NormalizedCacheObject>
  locale?: string
}) {
  const { data } = await client.query<GetTheatricalLandingReleasesQuery, GetTheatricalLandingReleasesQueryVariables>({
    query: GET_THEATRICAL_LANDING_RELEASES,
    variables: { input: { language: locale } },
    errorPolicy: 'all',
  })

  return data?.theatricalReleases?.theatricalRelease
}
